<template>
  <div>
    <load-profile></load-profile>
    <!-- Table Container Card -->
    <b-card>
      <validation-observer ref="editForm" #default="{ invalid }">
        <!-- form -->
        <n-form-confirmation
          key="editForm"
          @submit="submit"
          :form="$refs.editForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data" :initValue="initData">
            <template #parentId="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-async-single-select
                    v-model="data[item.field.key]"
                    :name="item.field.key"
                    :repository="item.field.repository"
                    :selection-key="item.field.selectionKey"
                    :selection-label="item.field.selectionLabel"
                    @optionChange="changeOption"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                    :readonly="true"
                  ></n-async-single-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
            <template #loginTimeout="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-single-select
                    :reduce="true"
                    v-model="data[item.field.key]"
                    :options="timeout"
                    :clearable="false"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  ></n-single-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading
                type="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                :loading="loading"
                :disabled="invalid"
                v-if="$can('update', 'assistant')"
              >
                {{ $t("button.saveChanges") }}
              </n-button-loading>
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import Alphabet from "@/data/numberAlphabet";
import Timeout from "@/data/timeout";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import NSingleSelect from "@/components/NSingleSelect";

const AssistantRepository = Repository.get("assistant");

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    NAsyncSingleSelect,
    NSingleSelect,
  },
  directives: {
    Ripple,
  },
  computed: {
    userData: function () {
      return this.$store.state.profile.name
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem("userData"));
    },
  },
  data() {
    return {
      data: {
        firstName: null,
        lastName: null,
        image: null,
        gender: "male",
        username: null,
        email: null,
        password: null,
      },
      initData: {},
      loading: false,
      checkUsernameLoading: false,
      usernameValid: false,
      getSuggestionUsernameLoading: false,
      username: {
        parent: null,
        first: null,
        second: null,
        third: null,
        fourth: null,
      },
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    changeOption(value) {
      this.username.parent = value?.username;
    },
    show() {
      AssistantRepository.show(this.$route.params.id).then((response) => {
        const data = response.data.data;
        this.initData = {
          ...data,
        };
      });
    },
    submit() {
      this.$refs.editForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          AssistantRepository.update(this.$route.params.id, this.data)
            .then((response) => {
              this.$router.push({
                name: "view-assistant",
                params: { id: response.data.data.id },
              });
            })
            .catch((error) => {
              if (error.response?.status == 422) {
                this.$refs.editForm.setErrors(error.response?.data?.errors);
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
    concatUsername() {
      if (
        this.username.first &&
        this.username.second &&
        this.username.third &&
        this.username.fourth &&
        this.username.parent
      ) {
        this.data.username = `${this.username.parent}${this.username.first}${this.username.second}${this.username.third}${this.username.fourth}`;
      }
    },
    back() {
      this.$router.back();
    },
  },
  setup() {
    const fields = FormInput.map((value) => {
      if (value.key === "password" || value.key === "retypePassword") {
        value.rules = value.rules.replace("required|", "");
        value.rules = value.rules.replace("required", "");
      } else if (value.key === "givenCredit") {
        value.disabled = true;
      }

      return value;
    });

    const alphabet = [...Alphabet];
    const timeout = [...Timeout];
    return { fields, alphabet, timeout };
  },
};
</script>
